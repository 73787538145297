import { defineStore } from 'pinia'
import axios from 'redaxios'

const STORE_ID = 'userStore'

interface SortFilter {
    field: string,
    direction: number
}

interface User {
  username: string
  favorites: string[]
  isAdmin: boolean
  email: string
  dashboardFilters: {
    sort?: SortFilter
    submittedBy?: string
    favorites?: boolean
    searchString?: string
    scrollPosition: number
    tags?: string[]
  }
}

interface UserStore {
  user: User
  fetchUserData: () => Promise<void>
  setUsername: (username: string) => void
  updateDashboardFilter: (currentUsername: string, newUsername: string) => void
  toggleFavorite: (recipeUrl: string) => Promise<void>
  addFavorite: (recipeUrl: string) => Promise<void>
  removeFavorite: (recipeUrl: string) => Promise<void>
  isFavoriteRecipe: (recipeUrl: string) => boolean
  setDashboardSort: (sort: SortFilter) => Promise<void>
  setDashboardSubmittedBy: (submitted: string) => Promise<void>
  setDashboardFavorites: () => Promise<void>
  resetDashboardFavorites: () => void
  setDashboardSearch: () => Promise<void>
  setDashboardScrollPosition: (scrollPosition: number) => void
  getDashboardScrollPosition: () => number
  addDashboardTag: (tag: string) => Promise<void>
  removeDashboardTag: (tag: string) => Promise<void>
  toggleDashboardTag: (tag: string) => Promise<void>
  resetDashboardTags: () => Promise<void>
  clear: () => void
}

export const useUserStore = defineStore(STORE_ID, {
  state: (): { user: User } => ({
    user: {
      username: '',
      favorites: [],
      isAdmin: false,
      email: '',
      dashboardFilters: {
        scrollPosition: 0,
        sort: { // Default sort is by date descending
          field: 'createdAt',
          direction: -1
        },
        tags: []
      }
    }
  }),
  actions: {
    async fetchUserData(this: UserStore): Promise<void> {
      try {
        const { data } = await axios.get('/api/user')
        this.user = data
        this.user.dashboardFilters.scrollPosition = 0
      } catch (error) {
        console.error('Error fetching user data:', error)
        throw error // Rethrow the error so the caller can handle it, e.g. redirect to login page in the route guard in the case of a 401
      }
    },

    setUsername(this: UserStore, username: string): void {
      this.user.username = username
    },

    updateDashboardFilter(this: UserStore, currentUsername: string, newUsername: string): void {
      const { dashboardFilters } = this.user
      if (dashboardFilters.submittedBy === currentUsername) {
        dashboardFilters.submittedBy = newUsername
      }
    },

    async toggleFavorite(this: UserStore, recipeUrl: string): Promise<void> {
      if (this.isFavoriteRecipe(recipeUrl)) {
        await this.removeFavorite(recipeUrl)
      } else {
        await this.addFavorite(recipeUrl)
      }
    },
    
    async addFavorite(this: UserStore, recipeUrl: string): Promise<void> {
      try {
        await axios.post(`/api/user/favorites/${recipeUrl}`)
        this.user.favorites.push(recipeUrl)
      } catch (error) {
        console.error('Error adding favorite:', error)
      }
    },

    async removeFavorite(this: UserStore, recipeUrl: string): Promise<void> {
      try {
        await axios.delete(`/api/user/favorites/${recipeUrl}`)
        const index = this.user.favorites.indexOf(recipeUrl)
        if (index !== -1) {
          this.user.favorites.splice(index, 1)
        }
      } catch (error) {
        console.error('Error removing favorite:', error)
      }
    },

    isFavoriteRecipe(this: UserStore, recipeUrl: string): boolean {
      return this.user.favorites.includes(recipeUrl)
    },

    async setDashboardSort(this: UserStore, sortFilter: SortFilter): Promise<void> {
      try {
        await axios.post('/api/user/dashboard/sort', { sort: sortFilter })
        this.user.dashboardFilters.sort = sortFilter
      } catch (error) {
        console.error('Error setting dashboard sort:', error)
      }
    },

    async setDashboardSubmittedBy(this: UserStore, submitted: string): Promise<void> {
      try {
        await axios.post('/api/user/dashboard/submittedBy', { submittedBy: submitted })
        this.user.dashboardFilters.submittedBy = submitted
      } catch (error) {
        console.error('Error setting submittedBy filter:', error)
      }
    },

    toggleDashboardFavorites(this: UserStore): void {
      this.user.dashboardFilters.favorites = !this.user.dashboardFilters.favorites
      this.setDashboardFavorites()
    },

    resetDashboardFavorites(this: UserStore): void {
      this.user.dashboardFilters.favorites = false
      this.setDashboardFavorites()
    },

    async setDashboardFavorites(this: UserStore): Promise<void> {
      try {
        await axios.post('/api/user/dashboard/favorites', { favorites: this.user.dashboardFilters.favorites })
      } catch (error) {
        console.error('Error setting dashboard favorites:', error)
      }
    },

    async setDashboardSearch(this: UserStore): Promise<void> {
      try {
        await axios.post('/api/user/dashboard/searchString', { searchString: this.user.dashboardFilters.searchString })
      } catch (error) {
        console.error('Error setting dashboard search:', error)
      }
    },

    setDashboardScrollPosition(this: UserStore, scrollPosition: number): void {
      this.user.dashboardFilters.scrollPosition = scrollPosition
    },

    getDashboardScrollPosition(this: UserStore): number {
      return this.user.dashboardFilters.scrollPosition
    },

    async addDashboardTag(this: UserStore, tag: string): Promise<void> {
      try {
        await axios.post('/api/tags/add', { tag })
        this.user.dashboardFilters.tags?.push(tag)
      } catch (error) {
        console.error('Error adding dashboard tag:', error)
      }
    },
    
    async removeDashboardTag(this: UserStore, tag: string): Promise<void> {
      try {
        await axios.delete('/api/tags/remove', { data: { tag } })
        const index = this.user.dashboardFilters.tags?.indexOf(tag)
        if (index !== undefined && index !== -1) {
          this.user.dashboardFilters.tags?.splice(index, 1)
        }
      } catch (error) {
        console.error('Error removing dashboard tag:', error)
      }
    },

    async toggleDashboardTag(this: UserStore, tag: string): Promise<void> {
      if (this.user.dashboardFilters.tags?.includes(tag)) {
        await this.removeDashboardTag(tag)
      } else {
        await this.addDashboardTag(tag)
      }
    },

    async resetDashboardTags(this: UserStore): Promise<void> {
      try {
        await axios.delete('/api/tags')
        this.user.dashboardFilters.tags = []
      } catch (error) {
        console.error('Error resetting dashboard tags:', error)
      }
    },

    clear(): void {
      this.user = {
        username: '',
        favorites: [],
        isAdmin: false,
        email: '',
        dashboardFilters: {
          scrollPosition: 0,
          sort: {
            field: 'createdAt',
            direction: -1
          },
          tags: []
        }
      }
    }
  }
})