<template>
  <div class="toast-container position-fixed bottom-0 start-50 translate-middle-x p-3">
    <div
      v-for="notification in notificationStore.notifications"
      :key="notification.id"
      class="toast show text-white"
      :class="toastClass(notification.type)"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      @click="closeToast(notification)"
    >
      <div class="toast-body" data-bs-dismiss="toast">
        {{ notification.message }}
      </div>
      <!-- Bootstrap progress bar -->
      <div class="progress progress-toast" style="height: 4px">
        <div
          class="progress-bar progress-bar-animated bg-light"
          role="progressbar"
          :style="{
            animationDuration: `${notification.autoCloseTime}ms`
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useNotificationStore } from '@/stores/NotificationStore'
const notificationStore = useNotificationStore()

const toastClass = (type) => {
  switch (type) {
    case 'success':
      return 'bg-success'
    case 'error':
      return 'bg-danger'
    default:
      return 'bg-primary'
  }
}

const closeToast = (notification) => {
  notificationStore.removeNotification(notification)
}
</script>

<style scoped>
.progress-toast {
  border-radius: 0 0 0.25rem 0.25rem;
  background-color: rgba(0, 0, 0, 0);
}

.progress-bar {
  width: 100%;
  animation-name: progress-shrink;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  opacity: 0.5;
}

@keyframes progress-shrink {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.toast {
  position: relative;
  overflow: hidden;
}
</style>
