import { createRouter, createWebHistory } from 'vue-router'
import PageLogin from '@/components/Public/PageLogin.vue'
import { recipeRoutes } from './recipeRoutes'
import { recipeEditorRoutes } from './recipeEditorRoutes'
import { adminRoutes } from './adminRoutes'
import { routeGuard } from './routeGuard'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/components/PageDashboard/PageDashboard.vue')
    },
    {
      path: '/recipe-editor/:id',
      name: 'RecipeEditor',
      component: () => import('@/components/PageRecipeEditor/PageRecipeEditor.vue')
    },
    recipeEditorRoutes,
    {
      path: '/recipe/:id',
      name: 'Recipe',
      component: () => import('@/components/PageRecipe/PageRecipe.vue')
    },
    recipeRoutes,
    adminRoutes,
    {
      path: '/login',
      name: 'Login',
      component: PageLogin,
      meta: { public: true }
    },
    {
      path: '/recover',
      name: 'RecoverRequest',
      component: () => import('@/components/Public/PageRecoverRequest.vue'),
      meta: { public: true }
    },
    {
      path: '/recover/:token',
      name: 'RecoverSubmit',
      component: () => import('@/components/Public/PageRecoverSubmit.vue'),
      meta: { public: true }
    },
    {
      path: '/passcode',
      name: 'Passcode',
      component: () => import('@/components/Public/PageRegisterPasscode.vue'),
      meta: { public: true }
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/components/Public/PageRegister.vue'),
      meta: { public: true }
    },
    {
      path: '/status',
      name: 'Status',
      component: () => import('@/components/Public/PageStatus.vue'),
      meta: { public: true }
    }
  ]
})

// Route guard implementation
router.beforeEach(routeGuard)

export default router
