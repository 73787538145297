import { defineStore } from 'pinia'

const STORE_ID = 'notificationStore'

export enum NotificationType {
    Success = 'success',
    Error = 'error',
    Primary = 'primary'
}

interface Notification {
  id?: string; // Add ID field for tracking
  type: NotificationType
  message: string
  autoCloseTime: number
  timerId?: number // Add timerId to track setTimeout references
}

interface NotificationStore {
  notifications: Notification[]
  success: (message: string, autoCloseTime?: number) => void
  error: (message: string, autoCloseTime?: number) => void
  general: (message: string, autoCloseTime?: number) => void
  addNotification: (notification: Notification) => void
  removeNotification: (notification: Notification) => void
}

export const useNotificationStore = defineStore(STORE_ID, {
  state: (): { notifications: Notification[] } => ({
    notifications: []
  }),

  actions: {
    success(this: NotificationStore, message: string, autoCloseTime = 5000): void {
      this.addNotification({ type: NotificationType.Success, message, autoCloseTime })
    },

    error(this: NotificationStore, message: string, autoCloseTime = 5000): void {
      this.addNotification({ type: NotificationType.Error, message, autoCloseTime })
    },

    general(this: NotificationStore, message: string, autoCloseTime = 5000): void {
      this.addNotification({ type: NotificationType.Primary, message, autoCloseTime })
    },

    addNotification(this: NotificationStore, notification: Notification): void {
      // Generate unique ID for the notification
      notification.id = Date.now().toString();
      
      // Check if a notification with the same message and type already exists
      const existingNotificationIndex = this.notifications.findIndex(
        n => n.message === notification.message && n.type === notification.type
      )
      
      if (existingNotificationIndex !== -1) {
        // Get the existing notification
        const existingNotification = this.notifications[existingNotificationIndex]
        
        // Clear the existing timer
        if (existingNotification.timerId) {
          clearTimeout(existingNotification.timerId)
        }
        
        // Create new timer
        const timerId = window.setTimeout(() => {
          this.removeNotification(existingNotification)
        }, notification.autoCloseTime) as unknown as number
        
        // Update the existing notification's timer and ID
        this.notifications[existingNotificationIndex].timerId = timerId
        this.notifications[existingNotificationIndex].autoCloseTime = notification.autoCloseTime
        this.notifications[existingNotificationIndex].id = notification.id
      } else {
        // Create new timer for this notification
        const timerId = window.setTimeout(() => {
          this.removeNotification(notification)
        }, notification.autoCloseTime) as unknown as number
        
        // Add timerId to notification and push to array
        notification.timerId = timerId
        this.notifications.push(notification)
      }
    },
  
    removeNotification(this: NotificationStore, notification: Notification): void {
      const index = this.notifications.indexOf(notification)
      if (index !== -1) {
        // Clear the timer if it exists
        if (this.notifications[index].timerId) {
          clearTimeout(this.notifications[index].timerId)
        }
        this.notifications.splice(index, 1)
      }
    }
  }
})
