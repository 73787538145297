export const recipeEditorRoutes = {
  path: '/recipe-editor/:id',
  component: () => import('@/components/PageRecipeEditor/PageRecipeEditor.vue'),
  children: [
    {
      path: 'clear',
      name: 'RecipeEditorMenuClear',
      component: () => import('@/components/PageRecipeEditor/RecipeEditorMenuClear.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Clear Editor'
      }
    },
    {
      path: 'import',
      name: 'RecipeEditorMenuImport',
      component: () => import('@/components/PageRecipeEditor/RecipeEditorMenuImport.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Import Recipe'
      }
    }
  ]
}
