import { useUserStore } from '@/stores/UserStore'

export async function routeGuard(to, from, next) {
  const userStore = useUserStore()

  // For public routes, proceed if no guard is required
  if (to.meta.public) {
    return next()
  }

  // Otherwise, fetch user data if not already loaded
  if (!userStore.user.username) {
    try {
      // This API call requires a valid token
      await userStore.fetchUserData()
    } catch (error) {
      // If token is invalid, redirect to login page
      return next({ name: 'Login' })
    }
  }

  // Check for admin routes
  if (to.meta.requiresAdmin && !userStore.user.isAdmin) {
    // Redirect to dashboard if user is not an admin
    return next({ name: 'Dashboard' })
  }

  // Continue if user data is present
  next()
}
