export const recipeRoutes = {
  path: '/recipe/:id',
  component: () => import('@/components/PageRecipe/PageRecipe.vue'),
  children: [
    {
      path: 'upload-picture',
      name: 'RecipeUploadPicture',
      component: () => import('@/components/PageRecipe/FormPictureUpload.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Upload Picture'
      }
    },
    {
      path: 'comment',
      name: 'RecipeAddComment',
      component: () => import('@/components/PageRecipe/FormCommentAdd.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Add Comment'
      }
    },
    {
      path: 'personal-note',
      name: 'RecipePersonalNote',
      component: () => import('@/components/PageRecipe/FormPersonalNoteEdit.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Personal Note'
      }
    },
    {
      path: 'tags',
      name: 'RecipeTags',
      component: () => import('@/components/PageRecipe/FormTagEdit.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Tags'
      }
    },
    {
      path: 'share',
      name: 'RecipeShare',
      component: () => import('@/components/PageRecipe/FormRecipeShare.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Share Recipe'
      }
    },
    {
      path: 'delete',
      name: 'RecipeDelete',
      component: () => import('@/components/PageRecipe/FormRecipeDelete.vue'),
      meta: {
        isMenuContent: true,
        menuTitle: 'Delete Recipe'
      }
    }
  ]
}
