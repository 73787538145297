<template>
  <public-page-layout title="Log in">
    <form @submit.prevent="login">
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="username"
          v-model="username"
          placeholder="Username"
        />
        <label for="username">Username</label>
      </div>
      <div class="form-floating mb-3">
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
          placeholder="****"
        />
        <label for="password">Password</label>
      </div>
      <div class="d-grid gap-2">
        <button type="submit" class="btn btn-primary btn-lg" id="login-button" :disabled="isSaving">
          <div v-if="!isSaving">Log in</div>
          <div class="spinner-border spinner-border-sm text-light" role="status" v-if="isSaving">
            <span class="visually-hidden">Please wait...</span>
          </div>
        </button>
      </div>
    </form>
    <br />
    <div>
      <router-link to="/passcode" id="create-account-link">Create account</router-link> <br />
      <router-link to="/recover" id="recover-account-link">Recover account</router-link> <br />
      <router-link to="/status" id="system-status-link">System status</router-link>
    </div>
  </public-page-layout>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'redaxios'
import PublicPageLayout from '@/components/Layout/LayoutPagePublic.vue'
import { useUserStore } from '@/stores/UserStore'
import { useNotificationStore } from '@/stores/NotificationStore'

const router = useRouter()
const userStore = useUserStore()
const notificationStore = useNotificationStore()

const username = ref('')
const password = ref('')
const isSaving = ref(false)

const login = async () => {
  isSaving.value = true
  try {
    await axios.post('/api/login', {
      username: username.value,
      password: password.value
    })

    await userStore.fetchUserData()
    await router.push('/')
  } catch (error) {
    if (error?.status === 429) {
      notificationStore.error('Too many requests. Wait 5 minutes before trying again.')
    } else {
      notificationStore.error(error?.data?.message || error.message)
    }
  } finally {
    isSaving.value = false
  }
}

// Clear the stores when the component is mounted (e.g. on any route that renders this component)
onMounted(() => {
  userStore.clear()
})
</script>
